import { gql } from '@apollo/client';

//export ORGANIZATION_QUERY

//export const LOCATION_QUERY

export const CONNECTED_AGENCY_ORGANIZATION_FOR_COURIER_ORGANIZATION = gql`
  query ConnectedAgencyOrganizationForCourierOrganization(
    $agencyOrganizationId: String!
    $courierOrganizationId: String!
  ) {
    connectedAgencyOrganizationForCourierOrganization(
      agencyOrganizationId: $agencyOrganizationId
      courierOrganizationId: $courierOrganizationId
    ) {
      id
      name
      legalName
      shortName
      organizationType
      phone
      email
      description
      headquartersAddressLocation {
        id
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
      invoicingAddressLocation {
        id
        formattedAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
      invoiceEmail
      registrationAddressLocation {
        id
        formattedAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      vatId
      registrationNumber
      socialProfiles {
        platform
        type
        value
      }
      logo {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const CONNECTED_COURIER_ORGANIZATIONS_FOR_AGENCY_ORGANIZATION = gql`
  query ConnectedCourierOrganizationsForAgencyOrganization($agencyOrganizationId: String!) {
    connectedCourierOrganizationsForAgencyOrganization(
      agencyOrganizationId: $agencyOrganizationId
    ) {
      id
      connectionType
      createdAt
      updatedAt
      usedOrganizationInvitation {
        id
        usedAt
        usedByUser {
          id
          firstNames
          lastName
          email
        }
      }
      organization {
        id
        name
        legalName
        shortName
        organizationType
        description
        phone
        email
        organizationUserOwners {
          id
          roleType
          user {
            id
            firstNames
            lastName
            email
            phone
          }
        }
      }
    }
  }
`;

export const CONNECTED_AGENCY_ORGANIZATIONS_FOR_COURIER_ORGANIZATION = gql`
  query ConnectedAgencyOrganizationsForCourierOrganization($courierOrganizationId: String!) {
    connectedAgencyOrganizationsForCourierOrganization(
      courierOrganizationId: $courierOrganizationId
    ) {
      id
      connectionType
      createdAt
      updatedAt
      usedOrganizationInvitation {
        id
        usedAt
        usedByUser {
          id
          firstNames
          lastName
          email
        }
      }
      organization {
        id
        name
        legalName
        shortName
        organizationType
        description
        phone
        email
        organizationUserOwners {
          id
          roleType
          user {
            id
            firstNames
            lastName
            email
            phone
          }
        }
      }
    }
  }
`;

export const ORGANIZATIONS_QUERY = gql`
  query Organizations {
    organizations {
      id
      name
      legalName
      createdAt
      organizationType
      usedOrganizationInvitation {
        id
        usedAt
        organizationInvitationType
      }
      organizationUsers {
        id
        roleType
        createdAt
        user {
          id
          firstNames
          lastName
          email
          createdAt
        }
      }
    }
  }
`;

export const ORGANIZATION_INVITATION_BY_ACTIVATION_CODE_FOR_COURIER_ORGANIZATION_QUERY = gql`
  query OrganizationInvitationByActivationCodeForCourierOrganization(
    $organizationId: String!
    $activationCode: String!
  ) {
    organizationInvitationByActivationCodeForCourierOrganization(
      organizationId: $organizationId
      activationCode: $activationCode
    ) {
      id
      activationCode
      organizationInvitationType
      invitingOrganization {
        id
        name
        legalName
        shortName
        phone
        email
      }
    }
  }
`;

export const AVAILABLE_COURIER_USERS_BY_DISTANCE_FOR_AGENCY_JOB_QUERY = gql`
  query AvailableCourierUsersByDistanceForAgencyJob($agencyJobId: String!) {
    availableCourierUsersByDistanceForAgencyJob(agencyJobId: $agencyJobId) {
      user {
        id
        firstNames
        lastName
        email
        phone
        agencyCourierUserProfile {
          internalComment
          markedForAttention
          organizationCourierCategories {
            id
            order
            label
          }
        }
      }
      organizationUsers {
        id
        roleType
        organization {
          id
          name
          legalName
          shortName
          organizationType
        }
        user {
          id
        }
      }
      courierJob {
        id
        relationshipType
        declineReasonType
        isActive
      }
      availabilityLocationsWithDistance {
        availabilityLocation {
          id
          timestamp
          availability {
            id
            isAdHocAvailability
            startTime
            endTime
            timeZoneIdentifier
          }
          location {
            id
            formattedShortAddress
            formattedMinimalAddress
            formattedAddress
            timeZoneIdentifier
          }
        }
        distance
      }
    }
  }
`;

export const ALL_ACTIVE_AND_UPCOMING_AVAILABILITIES_FOR_PRIOJET_QUERY = gql`
  query AllActiveAndUpcomingAvailabilitiesForPriojet {
    allActiveAndUpcomingAvailabilitiesForPriojet {
      id
      isAdHocAvailability
      startTime
      endTime
      updatedAt
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        formattedAddress
        formattedMinimalAddress
        timeZoneIdentifier
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
        courierUserProfile {
          id
          baseAirports
        }
      }
      lastDeviceLocationWithLocationForUser {
        id
        timestamp
        location {
          id
          formattedAddress
          formattedMinimalAddress
          timeZoneIdentifier
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const ACTIVE_AND_UPCOMING_AVAILABILITIES_FOR_CONNECTED_COURIER_ORGANIZATIONS_QUERY = gql`
  query ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations(
    $agencyOrganizationId: String!
  ) {
    activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations(
      agencyOrganizationId: $agencyOrganizationId
    ) {
      id
      isAdHocAvailability
      startTime
      endTime
      updatedAt
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        formattedAddress
        formattedMinimalAddress
        timeZoneIdentifier
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
        profilePicture {
          sasUrl
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        courierUserProfile {
          id
          baseAirports
        }
      }
      lastDeviceLocationWithLocationForUser {
        id
        timestamp
        location {
          id
          formattedAddress
          timeZoneIdentifier
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const AVAILABILITIES_FOR_USER_QUERY = gql`
  query AvailabilitiesForUser($userId: String!) {
    availabilitiesForUser(userId: $userId) {
      id
      isAdHocAvailability
      startTime
      endTime
      updatedAt
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        formattedAddress
        formattedMinimalAddress
        timeZoneIdentifier
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
        courierUserProfile {
          id
          baseAirports
        }
      }
      lastDeviceLocationWithLocationForUser {
        id
        timestamp
        location {
          id
          formattedAddress
          formattedMinimalAddress
          timeZoneIdentifier
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const ACTIVE_AVAILABILITIES_FOR_USER_QUERY = gql`
  query ActiveAvailabilitiesForUser($userId: String!) {
    activeAvailabilitiesForUser(userId: $userId) {
      id
      isAdHocAvailability
      startTime
      endTime
      updatedAt
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        formattedAddress
        formattedMinimalAddress
        timeZoneIdentifier
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
      }
      lastDeviceLocationWithLocationForUser {
        id
        timestamp
        location {
          id
          formattedAddress
          formattedMinimalAddress
          timeZoneIdentifier
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const USER_IDENTITY_DOCUMENTS_QUERY = gql`
  query UserIdentityDocuments($userId: String!) {
    userIdentityDocuments(userId: $userId) {
      id
      firstNames
      lastName
      placeOfBirth
      sex
      countryOfBirth
      dateOfBirth
      documentType
      description
      documentFeature
      documentSubType
      documentSubTypeMulti
      documentNumber
      dateOfIssue
      placeOfIssue
      countryOfIssue
      expiresOn
      isInternationalDrivingPermit
      expiresOnInternational
      nationality
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const USER_IDENTITY_DOCUMENT_QUERY = gql`
  query UserIdentityDocument($documentId: String!) {
    userIdentityDocument(documentId: $documentId) {
      id
      firstNames
      lastName
      countryOfBirth
      placeOfBirth
      sex
      dateOfBirth
      documentType
      documentFeature
      description
      documentSubType
      documentSubTypeMulti
      documentNumber
      dateOfIssue
      placeOfIssue
      countryOfIssue
      expiresOn
      isInternationalDrivingPermit
      expiresOnInternational
      nationality
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const USER_TRAVEL_DOCUMENTS_QUERY = gql`
  query UserTravelDocuments($userId: String!) {
    userTravelDocuments(userId: $userId) {
      id
      firstNames
      lastName
      placeOfBirth
      sex
      dateOfBirth
      documentType
      documentSubType
      description
      documentSubTypeMulti
      documentNumber
      dateOfIssue
      placeOfIssue
      countryOfIssue
      validForCountry
      expiresOn
      nationality
      countryOfBirth
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      multipleEntriesAllowed
      mappedIdentityDocument {
        id
        nationality
        firstNames
        lastName
        placeOfBirth
        sex
        countryOfBirth
        dateOfBirth
        documentNumber
      }
    }
  }
`;
export const USER_TRAVEL_DOCUMENT_QUERY = gql`
  query UserTravelDocument($documentId: String!) {
    userTravelDocument(documentId: $documentId) {
      id
      firstNames
      lastName
      placeOfBirth
      sex
      dateOfBirth
      description
      documentType
      documentSubType
      documentSubTypeMulti
      documentNumber
      dateOfIssue
      placeOfIssue
      countryOfIssue
      validForCountry
      expiresOn
      nationality
      countryOfBirth
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      mappedIdentityDocument {
        id
        documentNumber
        firstNames
        lastName
        placeOfBirth
        sex
        countryOfBirth
        dateOfBirth
        nationality
      }
      multipleEntriesAllowed
    }
  }
`;

export const NUMBER_OF_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER = gql`
  query NumberOfNewNotificationsForOrganizationUser($organizationUserId: String!) {
    numberOfNewNotificationsForOrganizationUser(organizationUserId: $organizationUserId)
  }
`;

export const LATEST_OR_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER = gql`
  query LatestOrNewSystemNotificationsForOrganizationUser(
    $filterType: [NotificationSystemFilterType!]
    $organizationUserId: String!
  ) {
    latestOrNewSystemNotificationsForOrganizationUser(
      filterType: $filterType
      organizationUserId: $organizationUserId
    ) {
      id
      notificationType
      notificationMeansType
      organizationContextType
      language
      batchId
      job {
        id
      }
      agencyJob {
        id
        agencyJobStatusType
        courierJobs {
          id
          relationshipType
          responsibleCourierUser {
            id
          }
        }
      }
      courierJob {
        id
        agencyJob {
          id
        }
      }
      chatMessage {
        id
        chat {
          id
          agencyJob {
            id
          }
        }
      }
      title
      content
      contentType
      data
      sentAt
      receivedAt
      firstDisplayedAt
      markedAsReadAt
      createdAt
      updatedAt
    }
  }
`;

export const SIGN_IN_USER_CONTEXT_QUERY = gql`
  query SignInUserContext {
    signInUserContext {
      sub
      token {
        iss
        exp
        nbf
        aud
        oid
        sub
        city
        country
        name
        givenName
        familyName
        emails
        tfp
        azp
        ver
        iat
      }
      user {
        id
        firstNames
        email
        lastName
      }
      organizationUser {
        id
        roleType
      }
      organization {
        id
        name
        legalName
        organizationType
        usedOrganizationInvitation {
          id
          usedAt
        }
        organizationType
      }
      roleType
      organizationType
      role
      organizationActivatedAt
      device {
        id
        deviceType
      }
      deviceId
      userContextId
      organizationSubscriptionPlan {
        id
        isActive
        endTime
        prices {
          currency
          recurringInterval
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          value
          subscriptionFeatures {
            id
            name
            count
            value
          }
        }
      }
    }
  }
`;

export const ORGANIZATION_INVITATIONS_FOR_PRIOJET_QUERY = gql`
  query OrganizationInvitationsForPriojetOrganization {
    organizationInvitationsForPriojetOrganization {
      id
      createdAt
      updatedAt
      voidedAt
      usedAt
      reservedComment
      activationCode
      organizationInvitationType
      invitedOrganizationType
      roleType
      bitrix24Deal {
        id
        bitrixDealCategoryId
        bitrixInvitedCompanyId
        bitrixInvitedContactId
      }
      organizationContact {
        id
        firstNames
        lastName
        phone
        email
        isPriojetContact
        isCourierContact
        isAgencyContact
        isClientContact
        isTravelerContact
        isServiceProviderContact
        isOtherContact
        organization {
          id
          name
          legalName
          organizationType
        }
      }
      invitedUser {
        id
        firstNames
        lastName
        email
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      usedForOrganizationUser {
        id
        roleType
        user {
          id
          firstNames
          lastName
          email
        }
        organization {
          id
          name
          legalName
          organizationType
        }
      }
      usedForOrganization {
        id
        name
        legalName
        organizationType
        createdAt
      }
      usedForOrganizationOrganization {
        id
        connectionType
        connectingOrganization {
          id
          name
          legalName
          organizationType
          createdAt
        }
        connectedOrganization {
          id
          name
          legalName
          organizationType
          createdAt
        }
      }
      invitingOrganization {
        id
        name
        legalName
        organizationType
        createdAt
      }
      usedByUser {
        id
        firstNames
        email
        lastName
        createdAt
      }
      createdByUser {
        id
        firstNames
        email
        lastName
        createdAt
      }
      organizationInvitationSharings {
        id
        appName
        platformVersion
        platform
        language
        sharedAt
        sharedByUser {
          id
          firstNames
          email
          lastName
        }
      }
    }
  }
`;

export const COURIER_ORGANIZATION_INVITATIONS_FOR_AGENCY_ORGANIZATION_QUERY = gql`
  query CourierOrganizationInvitationsForAgencyOrganization(
    $agencyOrganizationId: String!
    $userId: String!
  ) {
    courierOrganizationInvitationsForAgencyOrganization(
      agencyOrganizationId: $agencyOrganizationId
      userId: $userId
    ) {
      id
      createdAt
      updatedAt
      voidedAt
      usedAt
      reservedComment
      activationCode
      organizationInvitationType
      invitedOrganizationType
      roleType
      hasInvitationDeal
      organizationContact {
        id
        firstNames
        lastName
        phone
        email
        isCourierContact
        isAgencyContact
        isClientContact
        organization {
          id
          name
          legalName
          organizationType
        }
        userContactsForUser(userId: $userId) {
          id
          expoContactId
          phone
          phoneUnformatted
          email
          firstNames
          lastName
          isCourierContact
          isAgencyContact
          isClientContact
        }
      }
      invitedUser {
        id
        firstNames
        lastName
        email
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      usedForOrganizationUser {
        id
        roleType
        user {
          id
          firstNames
          lastName
          email
        }
        organization {
          id
          name
          legalName
          organizationType
        }
      }
      usedForOrganization {
        id
        name
        legalName
        organizationType
      }
      usedForOrganizationOrganization {
        id
        connectionType
        connectingOrganization {
          id
          name
          legalName
          organizationType
        }
        connectedOrganization {
          id
          name
          legalName
          organizationType
        }
      }
      invitingOrganization {
        id
        name
        legalName
        organizationType
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      usedByUser {
        id
        firstNames
        email
        lastName
      }
      createdByUser {
        id
        firstNames
        email
        lastName
      }
    }
  }
`;

export const EMPLOYEE_INVITATIONS_FOR_AGENCY_ORGANIZATION_QUERY = gql`
  query EmployeeInvitationsForAgencyOrganization($agencyOrganizationId: String!, $userId: String!) {
    employeeInvitationsForAgencyOrganization(
      agencyOrganizationId: $agencyOrganizationId
      userId: $userId
    ) {
      id
      createdAt
      updatedAt
      voidedAt
      usedAt
      reservedComment
      activationCode
      organizationInvitationType
      invitedOrganizationType
      roleType
      hasInvitationDeal
      organizationContact {
        id
        firstNames
        lastName
        phone
        email
        isCourierContact
        isAgencyContact
        isClientContact
        organization {
          id
          name
          legalName
          organizationType
        }
        userContactsForUser(userId: $userId) {
          id
          expoContactId
          phone
          phoneUnformatted
          email
          firstNames
          lastName
          isCourierContact
          isAgencyContact
          isClientContact
        }
      }
      invitedUser {
        id
        firstNames
        lastName
        email
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      usedForOrganizationUser {
        id
        roleType
        user {
          id
          firstNames
          lastName
          email
        }
        organization {
          id
          name
          legalName
          organizationType
        }
      }
      usedForOrganization {
        id
        name
        legalName
        organizationType
      }
      usedForOrganizationOrganization {
        id
        connectionType
        connectingOrganization {
          id
          name
          legalName
          organizationType
        }
        connectedOrganization {
          id
          name
          legalName
          organizationType
        }
      }
      invitingOrganization {
        id
        name
        legalName
        organizationType
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      usedByUser {
        id
        firstNames
        email
        lastName
      }
      createdByUser {
        id
        firstNames
        email
        lastName
      }
    }
  }
`;

export const COURIER_USER_PROFILE_BY_USER_ID_QUERY = gql`
  query CourierUserProfileByUserId($userId: String!) {
    courierUserProfileByUserId(userId: $userId) {
      id
      inactivatedAt
      emergencyContactRelationship
      emergencyContactPhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactAddress
      hasAmexCreditCard
      hasDinerCreditCard
      hasMasterCreditCard
      hasVisaCreditCard
      hasAmexDebitCard
      hasDinerDebitCard
      hasMasterDebitCard
      hasVisaDebitCard
      amexCreditCardLimit
      dinerCreditCardLimit
      masterCreditCardLimit
      visaCreditCardLimit
      amexCreditCardCurrency
      dinerCreditCardCurrency
      masterCreditCardCurrency
      visaCreditCardCurrency
      hasObcExperience
      firstObcJobDateAt
      numberOfPastObcJobs
      hasMedicalObcExperience
      languagesKnown {
        language
        level
      }
      onboardExperiences
      taxCountry
      socialProfiles {
        platform
        type
        value
      }
      baseAirports
      baseAirportLocations {
        id
        iataCode
      }
      user {
        id
        firstNames
        email
        email2
        phone
        phone2
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        nationalities
        createdAt
        updatedAt
        inactivatedAt
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        userRegisteredAddressLocation {
          id
          formattedAddress
          addressName
          apartmentOrSuite
          streetName
          houseNumber
          city
          postalCode
          state
          country
        }
        organizationUsers {
          id
          organization {
            id
            legalName
            name
            email
            phone
            headquartersAddressLocation {
              id
              placeId
              formattedAddress
              formattedShortAddress
              addressName
              apartmentOrSuite
              streetName
              houseNumber
              city
              postalCode
              state
              country
            }
          }
        }
        agencyCourierUserProfile {
          internalComment
          markedForAttention
          organizationCourierCategories {
            id
            label
          }
        }
      }
    }
  }
`;

export const CURRENT_USER_CONTEXT_QUERY = gql`
  query CurrentUserContext {
    currentUserContext {
      sub
      token {
        iss
        exp
        nbf
        aud
        oid
        sub
        city
        country
        name
        givenName
        familyName
        emails
        tfp
        azp
        ver
        iat
      }
      user {
        id
        firstNames
        email
        lastName
      }
      organizationUser {
        id
        roleType
      }
      organization {
        id
        name
        legalName
        organizationType
        usedOrganizationInvitation {
          id
          usedAt
        }
        organizationType
      }
      roleType
      organizationType
      role
      organizationActivatedAt
      device {
        id
        deviceType
      }
      deviceId
      userContextId
      organizationSubscriptionPlan {
        id
        isActive
        startTime
        endTime
        isMain
        recurringInterval
        status
        prices {
          currency
          recurringInterval
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          value
          subscriptionFeatures {
            id
            name
            count
            value
          }
        }
      }
    }
  }
`;

export const USER_CONTEXTS_QUERY = gql`
  query UserContexts {
    userContexts {
      sub
      token {
        iss
        exp
        nbf
        aud
        oid
        sub
        city
        country
        name
        givenName
        familyName
        emails
        tfp
        azp
        ver
        iat
      }
      user {
        id
        firstNames
        email
        lastName
      }
      organizationUser {
        id
        roleType
      }
      organization {
        id
        name
        legalName
        organizationType
        usedOrganizationInvitation {
          id
          usedAt
        }
        organizationType
      }
      roleType
      organizationType
      role
      organizationActivatedAt
      device {
        id
        deviceType
      }
      deviceId
      userContextId
      organizationSubscriptionPlan {
        id
        isActive
        endTime
        prices {
          currency
          recurringInterval
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          value
          subscriptionFeatures {
            id
            count
            name
            value
          }
        }
      }
    }
  }
`;

export const COURIER_USER_PROFILE_FOR_COURIER_ORGANIZATION_USER_QUERY = gql`
  query CourierUserProfileForCourierOrganizationUser($organizationUserId: String!) {
    courierUserProfileForCourierOrganizationUser(organizationUserId: $organizationUserId) {
      id
      emergencyContactRelationship
      emergencyContactPhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactAddress
      hasAmexCreditCard
      hasDinerCreditCard
      hasMasterCreditCard
      hasVisaCreditCard
      hasAmexDebitCard
      hasDinerDebitCard
      hasMasterDebitCard
      hasVisaDebitCard
      amexCreditCardLimit
      dinerCreditCardLimit
      masterCreditCardLimit
      visaCreditCardLimit
      amexCreditCardCurrency
      dinerCreditCardCurrency
      masterCreditCardCurrency
      visaCreditCardCurrency
      hasObcExperience
      firstObcJobDateAt
      numberOfPastObcJobs
      hasMedicalObcExperience
      languagesKnown {
        language
        level
      }
      onboardExperiences
      taxCountry
      socialProfiles {
        platform
        type
        value
      }
      baseAirports
    }
  }
`;

export const USER_QUERY = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      firstNames
      lastName
      sex
      dateOfBirth
      placeOfBirth
      countryOfBirth
      language
      nationalities
      email
      phone
      email2
      phone2
      profilePicture {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      userRegisteredAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
    }
  }
`;

export const QUERY_USER_MINIMAL = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      firstNames
      lastName
    }
  }
`;

export const USER_FREQUENT_FLYER_DOCUMENTS_QUERY = gql`
  query UserFrequentFlyerDocuments($userId: String!) {
    userFrequentFlyerDocuments(userId: $userId) {
      id
      airlineType
      airlineStatusType
      airlineAllianceStatusType
      airlineAllianceType
      documentNumber
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const USER_FREQUENT_FLYER_DOCUMENT_QUERY = gql`
  query UserFrequentFlyerDocument($documentId: String!) {
    userFrequentFlyerDocument(documentId: $documentId) {
      id
      airlineType
      airlineStatusType
      airlineAllianceStatusType
      airlineAllianceType
      documentNumber
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const USER_VACCINATION_DOCUMENTS_QUERY = gql`
  query UserVaccinationDocuments($userId: String!) {
    userVaccinationDocuments(userId: $userId) {
      id
      diseaseType
      description
      vaccinationType
      vaccinationManufacturer
      dateOfVaccination
      isVaccinationComplete
      vaccinationCenter
      cityOfVaccination
      countryOfVaccination
      expiresOn
      documentNumber
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const USER_VACCINATION_DOCUMENT_QUERY = gql`
  query UserVaccinationDocument($documentId: String!) {
    userVaccinationDocument(documentId: $documentId) {
      id
      diseaseType
      description
      vaccinationType
      vaccinationManufacturer
      dateOfVaccination
      isVaccinationComplete
      vaccinationCenter
      cityOfVaccination
      countryOfVaccination
      expiresOn
      documentNumber
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const USER_HEALTH_REPORTS_QUERY = gql`
  query UserHealthReports($userId: String!) {
    userHealthReports(userId: $userId) {
      id
      diseaseType
      description
      testType
      dateTimeOfTest
      isResultPositive
      testCenter
      cityOfTest
      cityOfTestLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      countryOfTest
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const USER_HEALTH_REPORT_QUERY = gql`
  query UserHealthReport($documentId: String!) {
    userHealthReport(documentId: $documentId) {
      id
      diseaseType
      description
      testType
      dateTimeOfTest
      isResultPositive
      testCenter
      cityOfTest
      cityOfTestLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      countryOfTest
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const USER_BACKGROUND_DOCUMENTS_QUERY = gql`
  query UserBackgroundDocuments($userId: String!) {
    userBackgroundDocuments(userId: $userId) {
      id
      name
      issuingInstitution
      countryOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const USER_BACKGROUND_DOCUMENT_QUERY = gql`
  query UserBackgroundDocument($documentId: String!) {
    userBackgroundDocument(documentId: $documentId) {
      id
      name
      issuingInstitution
      countryOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const USER_OTHER_DOCUMENTS_QUERY = gql`
  query UserOtherDocuments($userId: String!) {
    userOtherDocuments(userId: $userId) {
      id
      name
      description
      countryOfIssue
      placeOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const USER_OTHER_DOCUMENT_QUERY = gql`
  query UserOtherDocument($documentId: String!) {
    userOtherDocument(documentId: $documentId) {
      id
      name
      description
      countryOfIssue
      placeOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const QUERY_USER_WITH_ALL_USER_DOCUMENTS = gql`
  query userWithAllUserDocuments($userId: String!) {
    userWithAllUserDocuments(userId: $userId) {
      id
      userBackgroundDocument {
        id
      }
      userFrequentFlyerDocuments {
        id
      }
      userHealthReports {
        id
      }
      userIdentityDocuments {
        id
      }
      userOtherDocuments {
        id
      }
      userTravelDocuments {
        id
      }
      userVaccinationDocuments {
        id
      }
    }
  }
`;

export const COURIER_JOB_FOR_COURIER_ORGANIZATION_QUERY = gql`
  query CourierJobForCourierOrganization($courierOrganizationId: String!, $courierJobId: String!) {
    courierJobForCourierOrganization(
      courierOrganizationId: $courierOrganizationId
      courierJobId: $courierJobId
    ) {
      id
      relationshipType
      courierJobStatusType
      declineReasonType
      relationshipTypeChangedAt
      fixedRate
      dailyRate
      hotelBudget
      kickOffFee
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      hasIncident
      responsibleCourierOrganization {
        id
        name
        legalName
        createdAt
        organizationType
      }
      responsibleCourierUser {
        id
        firstNames
        email
        phone
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        nationalities
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      agencyJob {
        id
        returnTimeAtStartAirport
        checkInTimeAtStartAirport
        returnTimeAtEndAirport
        checkInTimeAtEndAirport
        externalJobReference
        currency
        isDrivingLicenseRequiredForJob
        isCarRequiredForJob
        jobDetails
        numOfCouriersRequired
        hasIncident
        agencyJobStatusType
        numOfItems
        weightOfItems
        numOfCouriersRequired
        isAdditionalCostPaidByCourier
        additionalCostInformation
        startAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        endAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          formattedAirportAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        responsibleAgencyOrganization {
          id
          name
          legalName
          shortName
          organizationType
        }
        responsibleAgencyOrganizationUsers {
          id
          roleType
          organization {
            id
            name
            legalName
            shortName
            organizationType
          }
          user {
            id
            firstNames
            lastName
            email
          }
        }
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const COURIER_JOB_FOR_AGENCY_ORGANIZATION_QUERY = gql`
  query CourierJobForAgencyOrganization($agencyOrganizationId: String!, $courierJobId: String!) {
    courierJobForAgencyOrganization(
      agencyOrganizationId: $agencyOrganizationId
      courierJobId: $courierJobId
    ) {
      id
      relationshipType
      courierJobStatusType
      declineReasonType
      relationshipTypeChangedAt
      fixedRate
      dailyRate
      hotelBudget
      kickOffFee
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      hasIncident
      responsibleCourierOrganization {
        id
        name
        legalName
        createdAt
        organizationType
      }
      responsibleCourierUser {
        id
        firstNames
        email
        phone
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        nationalities
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      agencyJob {
        id
        returnTimeAtStartAirport
        checkInTimeAtStartAirport
        returnTimeAtEndAirport
        checkInTimeAtEndAirport
        externalJobReference
        currency
        isDrivingLicenseRequiredForJob
        isCarRequiredForJob
        jobDetails
        numOfCouriersRequired
        hasIncident
        agencyJobStatusType
        numOfItems
        weightOfItems
        numOfCouriersRequired
        isAdditionalCostPaidByCourier
        additionalCostInformation
        startAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        endAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          formattedAirportAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        responsibleAgencyOrganization {
          id
          name
          legalName
          shortName
          organizationType
        }
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const COURIER_JOBS_FOR_COURIER_ORGANIZATION_QUERY = gql`
  query CourierJobsForCourierOrganization($courierOrganizationId: String!) {
    courierJobsForCourierOrganization(courierOrganizationId: $courierOrganizationId) {
      id
      declineReasonType
      relationshipTypeChangedAt
      fixedRate
      dailyRate
      hotelBudget
      kickOffFee
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      hasIncident
      relationshipType
      courierJobStatusType
      responsibleCourierOrganization {
        id
        name
        legalName
        createdAt
        organizationType
      }
      responsibleCourierUser {
        id
        firstNames
        email
        phone
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        nationalities
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      agencyJob {
        id
        returnTimeAtStartAirport
        checkInTimeAtStartAirport
        returnTimeAtEndAirport
        checkInTimeAtEndAirport
        externalJobReference
        currency
        isDrivingLicenseRequiredForJob
        isCarRequiredForJob
        jobDetails
        numOfCouriersRequired
        hasIncident
        agencyJobStatusType
        relationshipType
        startAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        endAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          formattedAirportAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        responsibleAgencyOrganization {
          id
          name
          legalName
          shortName
          organizationType
        }
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const COURIER_JOBS_FOR_COURIER_ORGANIZATION_MINIMAL_QUERY = gql`
  query CourierJobsForCourierOrganizationMinimal($courierOrganizationId: String!) {
    courierJobsForCourierOrganizationMinimal(courierOrganizationId: $courierOrganizationId) {
      id
      courierJobStatusType
      relationshipType
      agencyJob {
        id
        agencyJobStatusType
      }
      job {
        id
      }
    }
  }
`;

export const AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_PAGINATED_QUERY = gql`
  query AgencyJobsForAgencyOrganizationPaginated($data: AgencyJobsForAgencyOrganizationInput!) {
    agencyJobsForAgencyOrganizationPaginated(data: $data) {
      edges {
        node {
          id
          returnTimeAtStartAirport
          checkInTimeAtStartAirport
          returnTimeAtEndAirport
          checkInTimeAtEndAirport
          externalJobReference
          hasIncident
          agencyJobStatusType
          internalNotes
          startAirport {
            id
            placeId
            formattedAddress
            formattedShortAddress
            formattedAirportAddress
            timeZoneIdentifier
            timeZoneRawOffset
          }
          endAirport {
            id
            placeId
            formattedAddress
            formattedShortAddress
            formattedAirportAddress
            timeZoneIdentifier
            timeZoneRawOffset
          }
          job {
            id
            priojetJobIdentifier
            pickupTime
            pickupAddressDescription
            dropOffTime
            pickupLocation {
              id
              placeId
              formattedAddress
              formattedShortAddress
              timeZoneIdentifier
              timeZoneRawOffset
            }
            dropOffLocation {
              id
              placeId
              formattedAddress
              formattedShortAddress
              timeZoneIdentifier
              timeZoneRawOffset
            }
          }
          courierJobs {
            id
            relationshipType
          }
          responsibleAgencyOrganization {
            id
            linkCO2Emissions
          }
        }
      }
    }
  }
`;

export const AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY = gql`
  query AgencyJobsForAgencyOrganizationMinimal($agencyOrganizationId: String!) {
    agencyJobsForAgencyOrganization(agencyOrganizationId: $agencyOrganizationId) {
      id
      agencyJobStatusType
    }
  }
`;

export const AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY = gql`
  query AgencyJobsForAgencyOrganization($agencyOrganizationId: String!) {
    agencyJobsForAgencyOrganization(agencyOrganizationId: $agencyOrganizationId) {
      id
      returnTimeAtStartAirport
      checkInTimeAtStartAirport
      returnTimeAtEndAirport
      checkInTimeAtEndAirport
      externalJobReference
      hasIncident
      agencyJobStatusType
      internalNotes
      startAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        formattedAirportAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      endAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        formattedAirportAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      job {
        id
        priojetJobIdentifier
        pickupTime
        pickupAddressDescription
        dropOffTime
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
      courierJobs {
        id
        relationshipType
      }
    }
  }
`;

export const COURIER_JOBS_FOR_AGENCY_JOB_QUERY = gql`
  query CourierJobsForAgencyJob($agencyJobId: String!) {
    courierJobsForAgencyJob(agencyJobId: $agencyJobId) {
      id
      relationshipType
      courierJobStatusType
      declineReasonType
      fixedRate
      dailyRate
      hotelBudget
      kickOffFee
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      hasIncident
      responsibleCourierOrganization {
        id
        name
        shortName
        legalName
        createdAt
        organizationType
      }
      responsibleCourierUser {
        id
        firstNames
        email
        phone
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        nationalities
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      agencyJob {
        id
        returnTimeAtStartAirport
        checkInTimeAtStartAirport
        returnTimeAtEndAirport
        checkInTimeAtEndAirport
        externalJobReference
        currency
        isDrivingLicenseRequiredForJob
        isCarRequiredForJob
        jobDetails
        numOfCouriersRequired
        hasIncident
        agencyJobStatusType
        startAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        endAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          formattedAirportAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
      courierJobAgencyJobChat {
        id
      }
    }
  }
`;

export const QUERY_COURIER_JOBS_COURIER_ORGANIZATION_USERS_FOR_AGENCY_JOB = gql`
  query CourierJobsCourierOrganizationUsersForAgencyJob($agencyJobId: String!) {
    courierJobsForAgencyJob(agencyJobId: $agencyJobId) {
      id
      responsibleCourierOrganization {
        id
        organizationUsers {
          id
        }
      }
    }
  }
`;

export const AGENCY_JOB_FOR_AGENCY_ORGANIZATION_QUERY = gql`
  query AgencyJobForAgencyOrganization($agencyJobId: String!) {
    agencyJobForAgencyOrganization(agencyJobId: $agencyJobId) {
      id
      returnTimeAtStartAirport
      checkInTimeAtStartAirport
      returnTimeAtEndAirport
      checkInTimeAtEndAirport
      externalJobReference
      comment
      fixedRate
      dailyRate
      kickOffFee
      hotelBudget
      currency
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      isDrivingLicenseRequiredForJob
      isCarRequiredForJob
      jobDetails
      numOfCouriersRequired
      hasIncident
      agencyJobStatusType
      internalNotes
      startAirport {
        id
        placeId
        formattedAddress
        formattedAirportAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
        types
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      responsibleAgencyOrganizationUsers {
        id
        roleType
        organization {
          id
          name
          legalName
          shortName
          organizationType
        }
        user {
          id
          firstNames
          lastName
          email
        }
      }
      endAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        formattedAirportAddress
        timeZoneIdentifier
        timeZoneRawOffset
        types
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        numOfItems
        weightOfItems
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          types
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          types
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
      clientJob {
        id
        organizationName
        organizationLegalName
        organizationPhone
        organizationEmail
        externalJobReference
        comment
        fixedRate
        currency
        hasIncident
      }
      courierJobs {
        id
        relationshipType
        courierJobStatusType
        declineReasonType
        fixedRate
        dailyRate
        hotelBudget
        kickOffFee
        isAccommodationBookedByCourier
        isAccommodationPaidByCourier
        isAdditionalCostPaidByCourier
        additionalCostInformation
        hasIncident
        responsibleCourierOrganization {
          id
          name
          legalName
          createdAt
          organizationType
        }
        courierJobAgencyJobChat {
          id
        }
        responsibleCourierUser {
          id
          firstNames
          email
          phone
          lastName
          sex
          dateOfBirth
          placeOfBirth
          countryOfBirth
          language
          nationalities
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
      }
    }
  }
`;

export const QUERY_AGENCY_JOB_FOR_AGENCY_ORGANIZATION_FOR_PLANNING = gql`
  query AgencyJobForAgencyOrganizationForPlanning($agencyJobId: String!) {
    agencyJobForAgencyOrganization(agencyJobId: $agencyJobId) {
      id
      externalJobReference
      startAirport {
        id
        placeId
        formattedAddress
        formattedAirportAddress
        formattedShortAddress
        iataCode
      }
      endAirport {
        id
        placeId
        formattedAddress
        formattedAirportAddress
        formattedShortAddress
        iataCode
      }
      job {
        id
        priojetJobIdentifier
      }
    }
  }
`;

export const QUERY_AGENCY_JOB_INTERNAL_NOTES = gql`
  query AgencyJobForAgencyOrganizationInternalNotes($agencyJobId: String!) {
    agencyJobForAgencyOrganization(agencyJobId: $agencyJobId) {
      id
      internalNotes
    }
  }
`;

export const AGENCY_COURIER_JOB_CHAT = gql`
  query AgencyCourierJobChat($agencyCourierJobChatInput: AgencyCourierJobChatInput!) {
    agencyCourierJobChat(agencyCourierJobChatInput: $agencyCourierJobChatInput) {
      id
      inactivatedAt
      courierJob {
        id
        relationshipType
        responsibleCourierOrganization {
          id
          name
          legalName
          createdAt
          organizationType
        }
        responsibleCourierUser {
          id
          firstNames
          email
          phone
          lastName
        }
        job {
          id
          priojetJobIdentifier
        }
        agencyJob {
          id
          agencyJobStatusType
          startedByAgencyAt
          agencyJobChat {
            id
          }
          endAirport {
            id
            formattedShortAddress
            formattedAirportAddress
          }
          responsibleAgencyOrganization {
            id
            shortName
            legalName
            organizationType
          }
        }
      }
    }
  }
`;

export const QUERY_AGENCY_JOB_CHAT = gql`
  query AgencyJobChat($agencyJobChatInput: AgencyJobChatInput!) {
    agencyJobChat(agencyJobChatInput: $agencyJobChatInput) {
      id
      inactivatedAt
      agencyJob {
        id
        agencyJobStatusType
        externalJobReference
        courierJobs {
          id
          relationshipType
          responsibleCourierUser {
            id
          }
        }
        startAirport {
          id
          iataCode
        }
        endAirport {
          id
          iataCode
        }
        responsibleAgencyOrganization {
          id
          shortName
          legalName
          organizationType
        }
        job {
          id
          priojetJobIdentifier
        }
      }
    }
  }
`;

export const CHAT_MESSAGES_BY_CHAT_ID_QUERY = gql`
  query ChatMessagesByChatId(
    $chatMessagesByChatIdInput: ChatMessagesByChatIdInput!
    $pagination: PaginationArgs
    $orderBy: OrderArgs
  ) {
    chatMessagesByChatId(
      chatMessagesByChatIdInput: $chatMessagesByChatIdInput
      pagination: $pagination
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          message
          messageType
          chatFile {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
          sendingUser {
            id
            firstNames
            lastName
            profilePicture {
              sasUrl
              cacheKey
              cacheTTL
              sasToken {
                version
                signature
                expiresOn
                permissions
                resource
              }
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          createdAt
          updatedAt
        }
        cursor
      }
      totalCount
    }
  }
`;

export const CHAT_MESSAGE_EDGE_QUERY = gql`
  query ChatMessageEdge($chatMessageId: String!) {
    chatMessageEdge(chatMessageId: $chatMessageId) {
      node {
        id
        message
        messageType
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;

export const ORGANIZATION_PROFILE_QUERY = gql`
  query OrganizationProfile($id: String!) {
    organization(id: $id) {
      id
      name
      shortName
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      invoiceEmail
      socialProfiles {
        platform
        type
        value
      }
      headquartersAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
      invoicingAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
      registrationAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      invoiceEmail
      logo {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const ORGANIZATION_ORGANIZATION_USERS_QUERY = gql`
  query OrganizationOrganizationUsers($id: String!) {
    organization(id: $id) {
      id
      name
      shortName
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      organizationUsers {
        id
        roleType
        createdAt
        user {
          id
          firstNames
          lastName
          email
          phone
          createdAt
        }
      }
    }
  }
`;

export const QUERY_USER_BY_ID = gql`
  query UserById($id: String!) {
    user(id: $id) {
      id
      firstNames
      lastName
      profilePicture {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const QUERY_MIN_SUPPORTED_APP_VERSION = gql`
  query MinSupportedAppVersion {
    minSupportedAppVersion {
      currentAppVersion
      minSupportedAppVersion
    }
  }
`;

export const QUERY_DELETED_ACCOUNTS_PRIOJET = gql`
  query DeletedAccountsPriojetOnly {
    deletedAccountsPriojetOnly {
      id
      firstNames
      lastName
      email
      dateOfBirth
      profilePicture {
        sasUrl
      }
      inactivatedAt
      organizationUsers {
        id
        user {
          id
        }
        organization {
          id
          organizationType
          name
          phone
          inactivatedAt
          logo {
            sasUrl
          }
          organizationUsers {
            id
            user {
              id
            }
          }
        }
      }
    }
  }
`;

export const QUERY_ORGANIZATION_USER_BY_ID = gql`
  query organizationUserById($organizationUserId: String!) {
    organizationUserById(organizationUserId: $organizationUserId) {
      id
      roleType
      user {
        firstNames
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        email
        phone
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
        }
      }
      organization {
        id
        organizationUsers {
          id
          roleType
        }
      }
    }
  }
`;

export const QUERY_SUBSCRIPTION_PLANS_AGENCY = gql`
  query subscriptionPlansAgency {
    subscriptionPlansAgency {
      month {
        description
        featureDescription
        name
        planId
        prices
        recurringInterval
        trialPeriod
        isMain
        subscriptionPlanType
      }
      year {
        description
        featureDescription
        name
        planId
        prices
        recurringInterval
        trialPeriod
        isMain
        subscriptionPlanType
      }
    }
  }
`;

export const QUERY_SUBSCRIPTION_PLANS_COURIER = gql`
  query subscriptionPlansCourier {
    subscriptionPlansCourier {
      month {
        description
        featureDescription
        name
        planId
        prices
        recurringInterval
        trialPeriod
        isMain
        subscriptionPlanType
      }
      year {
        description
        featureDescription
        name
        planId
        prices
        recurringInterval
        trialPeriod
        isMain
        subscriptionPlanType
      }
    }
  }
`;

export const QUERY_ORGANIZATION_SUBSCRIPTION_PLANS = gql`
  query OrganizationSubscriptionPlans($id: String!) {
    organization(id: $id) {
      id
      name
      shortName
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      organizationSubscriptionPlan {
        id
        isActive
        startTime
        endTime
        isMain
        recurringInterval
        status
        previousPaymentSubscriptionId
        prices {
          currency
          recurringInterval
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          value
          subscriptionFeatures {
            id
            name
            count
            value
          }
        }
      }
      organizationSubscriptionPlans {
        id
        isActive
        startTime
        endTime
        isMain
        recurringInterval
        status
        prices {
          currency
          recurringInterval
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          value
          subscriptionFeatures {
            id
            name
            count
            value
          }
        }
      }
    }
  }
`;

export const QUERY_AIRPORT_LOCATION_LIST_PAGINATED = gql`
  query AirportLocationListPaginated(
    $data: AirportLocationSearchInput
    $orderBy: OrderArgs
    $pagination: PaginationArgs
  ) {
    airportLocationListPaginated(data: $data, orderBy: $orderBy, pagination: $pagination) {
      totalCount
      edges {
        node {
          id
          iataCode
          formattedAddress
          placeId
          locationGeometry {
            location {
              lat
              lng
            }
          }
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
    }
  }
`;

export const QUERY_AIRPORT_LOCATION_LIST = gql`
  query AirportLocationList($data: AirportLocationListInput) {
    airportLocationList(data: $data) {
      id
      iataCode
      formattedAddress
      placeId
      locationGeometry {
        location {
          lat
          lng
        }
      }
      timeZoneIdentifier
      timeZoneRawOffset
    }
  }
`;

export const QUERY_AIRPORT_LOCATION_SEARCH = gql`
  query AirportLocationSearch($data: AirportLocationSearchInput!) {
    airportLocationSearch(data: $data) {
      id
      iataCode
      formattedAddress
      placeId
      locationGeometry {
        location {
          lat
          lng
        }
      }
      timeZoneIdentifier
      timeZoneRawOffset
    }
  }
`;

export const QUERY_AVAILABILITIES_FOR_CONNECTED_COURIER_ORGANIZATIONS = gql`
  query AvailabilitiesForConnectedCourierOrganizations(
    $agencyOrganizationId: String!
    $filterType: [AvailabilityFilterType!]
  ) {
    availabilitiesForConnectedCourierOrganizations(
      agencyOrganizationId: $agencyOrganizationId
      filterType: $filterType
    ) {
      id
      isAdHocAvailability
      startTime
      endTime
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        timeZoneIdentifier
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        firstNames
        lastName
      }
    }
  }
`;

export const QUERY_AVAILABILITY = gql`
  query Availability($availabilityId: String!, $agencyOrganizationId: String) {
    availability(availabilityId: $availabilityId, agencyOrganizationId: $agencyOrganizationId) {
      id
      isAdHocAvailability
      startTime
      endTime
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        formattedAddress
        formattedMinimalAddress
        timeZoneIdentifier
        timeZoneRawOffset
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
        profilePicture {
          sasUrl
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        courierUserProfile {
          id
          baseAirports
        }
        organizationUsers {
          id
          organization {
            id
          }
        }
        agencyCourierUserProfile {
          internalComment
          markedForAttention
          organizationCourierCategories {
            id
            label
            order
          }
        }
      }
    }
  }
`;

export const QUERY_ORGANIZATION_COURIER_CATEGORIES = gql`
  query OrganizationCourierCategories {
    organizationCourierCategories {
      id
      order
      label
    }
  }
`;

export const QUERY_ORGANIZATION_INVITATION_UPLOAD_CSV = gql`
  query organizationInvitationUploadCSV($data: OrganizationInvitationUploadCSVInput!) {
    organizationInvitationUploadCSV(data: $data) {
      contacts {
        firstName
        firstNameError
        lastName
        lastNameError
        fullName
        email
        emailError
        phoneNumber
        phoneNumberError
        status
      }
      organizationInvitationsNotUsed
      organizationInvitationsUsed
    }
  }
`;

export const QUERY_NOTIFICATION_SETTING_CURRENT_USER = gql`
  query notificationSettingCurrentUser {
    notificationSettingCurrentUser {
      id
      emailConnection
      emailJob
      pushAvailability
      pushChat
      pushConnection
      pushJob
      systemAvailability
      systemChat
      systemConnection
      systemJob
    }
  }
`;

export const QUERY_AVAILABILITY_FIND_ACTIVE_ADHOC_CURRENT_USER = gql`
  query availabilityFindActiveAdhocCurrentUser {
    availabilityFindActiveAdhocCurrentUser {
      id
      lastLocationForDevice {
        id
        formattedAddress
        formattedMinimalAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      isAdHocAvailability
      startTime
      endTime
      updatedAt
    }
  }
`;

export const QUERY_AVAILABLE_COURIER_USERS_BY_DISTANCE_FOR_AGENCY_JOB_FILTERED = gql`
  query AvailableCourierUsersByDistanceForAgencyJobFiltered(
    $agencyJobId: String!
    $filterType: [JobAvailabilityFilterType!]
  ) {
    availableCourierUsersByDistanceForAgencyJobFiltered(
      agencyJobId: $agencyJobId
      filterType: $filterType
    ) {
      user {
        id
        firstNames
        lastName
        email
        phone
        courierUserProfile {
          id
          baseAirports
        }
        agencyCourierUserProfile {
          internalComment
          markedForAttention
          organizationCourierCategories {
            id
            order
            label
          }
        }
      }
      organizationUsers {
        id
        roleType
        organization {
          id
          name
          legalName
          shortName
          organizationType
        }
        user {
          id
        }
      }
      courierJob {
        id
        relationshipType
        declineReasonType
        isActive
      }
      availabilityLocationsWithDistance {
        availabilityLocation {
          id
          timestamp
          availability {
            id
            isAdHocAvailability
            startTime
            endTime
            timeZoneIdentifier
          }
          location {
            id
            formattedShortAddress
            formattedMinimalAddress
            formattedAddress
            timeZoneIdentifier
          }
        }
        groupType
        distance
      }
      displayAvailabilityLocationWithDistance {
        availabilityLocation {
          id
          timestamp
          availability {
            id
            isAdHocAvailability
            startTime
            endTime
            timeZoneIdentifier
          }
          location {
            id
            formattedShortAddress
            formattedMinimalAddress
            formattedAddress
            timeZoneIdentifier
          }
        }
        distance
      }
      lastAvailabilityLocationWithDistance {
        availabilityLocation {
          id
          timestamp
          availability {
            id
            isAdHocAvailability
            startTime
            endTime
            timeZoneIdentifier
          }
          location {
            id
            formattedShortAddress
            formattedMinimalAddress
            formattedAddress
            timeZoneIdentifier
          }
        }
        distance
      }
    }
  }
`;

export const QUERY_AGENCY_JOB_PLANNING_FOR_AGENCY_JOB = gql`
  query AgencyJobPlanningForAgencyJob($agencyJobId: String!) {
    agencyJobPlanningForAgencyJob(agencyJobId: $agencyJobId) {
      id
      agencyJobPlanningItems {
        id
        active
        actualDateTime
        actualDateTimeSetAt
        updatedAt
        files {
          id
          storedAsset {
            sasUrl
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        formType
        id
        inactivatedAt
        label
        location {
          id
          formattedAddress
        }
        name
        order
        targetDateTime
      }
      agencyJobPlanningLegs {
        id
        active
        departureDate
        flightCarrierDesignation
        flightNumber
        order
        updatedAt
        flightTickets {
          id
          storedAsset {
            sasUrl
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        agencyJobPlanningItems {
          id
          active
          actualDateTime
          actualDateTimeSetAt
          createdAt
          files {
            id
            storedAsset {
              sasUrl
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          formType
          id
          inactivatedAt
          label
          location {
            id
            formattedAddress
          }
          name
          order
          targetDateTime
          updatedAt
        }
      }
    }
  }
`;

export const CHAT_MESSAGES_BY_CHAT_ID_FOR_AGENCY_QUERY = gql`
  query ChatMessagesByChatIdForAgency(
    $chatMessagesByChatIdInput: ChatMessagesByChatIdInput!
    $pagination: PaginationArgs
    $orderBy: OrderArgs
  ) {
    chatMessagesByChatId(
      chatMessagesByChatIdInput: $chatMessagesByChatIdInput
      pagination: $pagination
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          message
          messageType
          agencyJobPlanningItemHistoryLog {
            id
            active
            actualDateTime
            label
            targetDateTime
            note
            agencyJobPlanningLeg {
              id
              flightCarrierDesignation
              flightNumber
              order
            }
            files {
              id
              storedAsset {
                sasUrl
                cacheKey
                cacheTTL
                metadata {
                  fileName
                  mimeType
                  height
                  width
                  size
                }
              }
            }
            location {
              id
              formattedAddress
            }
          }
          chatFile {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
          sendingUser {
            id
            firstNames
            lastName
            profilePicture {
              sasUrl
              cacheKey
              cacheTTL
              sasToken {
                version
                signature
                expiresOn
                permissions
                resource
              }
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          createdAt
          updatedAt
        }
        cursor
      }
      totalCount
    }
  }
`;

export const CHAT_MESSAGE_EDGE_FOR_AGENCY_QUERY = gql`
  query ChatMessageEdgeForAgency($chatMessageId: String!) {
    chatMessageEdge(chatMessageId: $chatMessageId) {
      node {
        id
        message
        messageType
        agencyJobPlanningItemHistoryLog {
          id
          active
          actualDateTime
          label
          targetDateTime
          note
          agencyJobPlanningLeg {
            id
            flightCarrierDesignation
            flightNumber
            order
          }
          files {
            id
            storedAsset {
              sasUrl
              cacheKey
              cacheTTL
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          location {
            id
            formattedAddress
          }
        }
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;
